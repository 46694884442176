.sphere-divider {
  margin: 0;
  border-width: 0px;
  border-style: solid;
  border-color: #bdbdbd;
  border-bottom-width: 1px;
}

.sphere-divider--vertical {
  border-right-width: 1px;
  border-bottom-width: 0;
  height: auto;
}
