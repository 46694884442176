.sphere-dialog {
  z-index: 10;
  position: relative;
}

.sphere-dialog__overlay {
  overflow-y: auto;
  inset: 0px;
  position: fixed;
  width: 100vw;
}

.sphere-dialog__positioner {
  padding: 1rem;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  display: flex;
}

.sphere-dialog__modal {
  max-width: 28rem;
  width: 100%;
  position: relative;
  padding: 1.5rem;
  box-sizing: border-box;
}
