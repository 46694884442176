.sphere-optional-image__error-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: #e4ebff;
  border: 1px dashed #e4ebff;
}

.sphere-optional-image__icon {
  width: 25%;
  height: auto;
}

.sphere-optional-image__dummy-image {
  display: none;
}

.sphere-optional-image__clickable-image {
  cursor: zoom-in;
}
